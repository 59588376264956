@import "variables";
@import "colors";

.toolbar-container {
    display: inline-flex;
    align-items: center;
    button {
        margin-left: 8px;
    }
}
.toolbar-title {
    font-size: 1.125rem;
    color: $maptycs-main;
}
.table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    .filter-input {
        font-size: 14px;
        width: 300px !important;
        max-width: 100%;
    }
}
.table-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: 0px 1.6px 3.6px rgba(0, 0, 0, 0.13), 0px 0px 2.9px rgba(0, 0, 0, 0.11);
    overflow: hidden;
    .table-content {
        height: 100%;
        background-color: #fff;
        overflow: auto;
        width: 100%;
        table {
            width: 100%;
            thead {
                tr.mat-header-row {
                    height: $table-header-height;
                    border-bottom: 1px solid #e2e2e2;
                    th {
                        font-size: 12px;
                        text-transform: capitalize;
                        white-space: nowrap;
                        height: 0.625rem;
                        margin: 0.25rem 16.625rem 0 0.776rem;
                        font-size: 0.75rem;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.25;
                        letter-spacing: normal;
                        color: #08080d;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        height: 0.625rem;
                        margin: 0.125rem 19.312rem 0rem 0;
                        font-size: 0.75rem;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.25;
                        letter-spacing: normal;
                        color: #08080d;
                        &.cell-select {
                            padding-right: 16px;
                            white-space: nowrap !important;
                        }
                    }
                    &:nth-child(odd) {
                        background-color: $maptycs-table-odd;
                    }
                    &:nth-child(even) {
                        background-color: $maptycs-table-even;
                    }
                }
            }
            span {
                .mat-icon {
                    color: #323232;
                    cursor: pointer;
                    outline: none;
                    width: 0.75rem;
                    height: 0.75rem;
                    &:hover {
                        color: $maptycs-accent;
                    }
                }
                &.disabled {
                    .mat-icon {
                        pointer-events: none;
                        color: $maptycs-table-icons-disabled;
                    }
                }
            }
            .table-cell-icons {
                >span {
                    position: relative;
                    padding: 0 12px;
                    &:before {
                        content: "";
                        position: absolute;
                        height: 28px;
                        width: 1px;
                        bottom: -2px;
                        left: 0;
                        background-color: #dddfe1;
                    }
                    &:first-child {
                        padding-left: 0;
                        &:before {
                            content: none;
                        }
                    }
                }
            }
            .mat-header-cell {
                font-size: 12px;
                color: #2d2d2d;
            }
        }
    }
}
table tr.mat-footer-row,
tr.mat-row {
    height: 32px !important;
}