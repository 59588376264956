@import "colors";
@import "variables";

.nested-menu-search {
    margin-bottom: 8px;

    input {
        padding: 0.625rem 0.312rem 0.625rem 0.321rem;
        position: relative;
        width: 100%;
        outline: none;
        background-color: rgba(0, 0, 0, 0);
        color: #002d57;
        cursor: inherit;
        font: inherit;
        opacity: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        border: 1px solid #002d57;
        border-radius: 2px;
        font-size: 12px;
        height: $menu-item-input-height;
    }
}
.overlay-filter-search{
    margin-bottom: 8px;

    input {
        padding: 0.625rem 0.312rem 0.625rem 0.321rem;
        position: relative;
        outline: none;
        background-color: rgba(0, 0, 0, 0);
        color: #002d57;
        cursor: inherit;
        font: inherit;
        opacity: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        border: 1px solid #002d57;
        border-radius: 2px;
        font-size: 12px;
        height: 16px;
        width: 7.813rem;
        border-top: 1px solid #002d57;
        margin-top: 8px !important;
    }
}

.nested-menu {
    .dropdown-small {
        line-height: 25px;
    }

    .nested-menu-item-list {
        padding: 10px 0;
    }

    .mat-menu-item {
        font-size: 0.75rem;
        height: fit-content;
        color: #08080d;
        line-height: 1.8;

        .mat-icon {
            margin: 0 !important;
            color: #08080d;
            font-size: 0.75rem;
        }

        &.colored {
            color: $maptycs-accent;
        }

        &.disabled {
            opacity: 0.3;
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    .mat-menu-content:not(:empty) {
        padding: 0 0 0px;
    }

    &.mat-menu-panel {
        min-height: 48px !important;
        max-height: 400px;
        top: 20px;
    }
    &.analytics{
        top: 0px !important;
    }
    &.custom-menu {
        left: 16px !important;
    }

    mat-checkbox {
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    .mat-checkbox-layout {
        width: 100%;
        height: 100%;
        padding: 0 6px;

        .mat-checkbox-label {
            width: 8.813rem;
            line-height: $menu-item-height;
            font-size: 0.75rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .mat-checkbox .mat-checkbox-frame {
        transform: scale(0.8);
    }

    .mat-checkbox-checked .mat-checkbox-background {
        transform: scale(0.8);
    }

    .checkbox-region.mat-menu-item {
        padding: 0 !important;
    }
}

.nested-menu.grouping-menu .mat-menu-item {
    height: 25px !important;
}

// View(table view)- Formula
.mat-menu-item .formula-name .mat-checkbox-layout .mat-checkbox-label {
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-menu-item {
    padding: 0 !important;
}