@import "colors";
@import "../styles/variables";

.toolbar {

    display: flex;
    justify-content: space-between;
    min-width: 100%;

    position: relative;
    height: $toolbar-height;
    min-height: $toolbar-height;
    background-color: $maptycs-toolbar;
    text-transform: capitalize;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 6px;
    border-top: 1px solid $maptycs-white;
    z-index: 101;


    .toolbar-account-container {
        padding: 10px 32px;
        display: flex;
        align-items: center;
        font-size: 22px;
        color: #00000026;
        font-weight: 700;
        white-space: nowrap;
    }

    .toolbar-content {
        height: 100%;
        padding-left: 35px;

        .toolbar-section {
            display: inline-flex;
            position: relative;
            height: 100%;
            align-items: center;
            border-right: 1px solid $maptycs-toolbar-separator;

            .col {
                display: grid;
                height: $toolbar-row-height*2;
                column-gap: 30px;
                padding: 0 30px 0px 10px;
                align-items: center;
                grid-template-rows: $toolbar-row-height $toolbar-row-height;

                & > span, span.toolbar-fake-option {
                    font-size: $font-small;
                    color: $maptycs-white;
                    max-height: $toolbar-height/2;
                    font-weight: normal;
                    text-transform: capitalize;
                    white-space: nowrap;
                    cursor: pointer;
                    user-select: none;

                    > mat-icon, > .mat-icon {
                        font-size: 20px;
                        vertical-align: middle;
                        width: 20px;
                        color: $maptycs-white;
                        margin-right: 5px;
                    }

                    > .mat-icon {
                        height: 16px;
                    }
                }


                &.col-2 {
                    grid-template-columns: auto auto;
                }

                &.col-3 {
                    grid-template-columns: auto auto auto;
                }

                &.col-1 {
                    grid-template-columns: auto;
                }
            }

            .col-title { // to be removed all this
                position: absolute;
                bottom: 8px;
                right: 12px;
                display: none;
                color: #7d7d7d;
                opacity: 0.6;
                font-size: 12px;
            }
        }
    }
}

.account-settings-title {
    padding: 10px 32px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #00000026;
    font-weight: 600;
    margin-left: auto;
    font-family: ProximaNova;
    text-transform: capitalize;
}


.location-search-container {
    display: flex;
    flex-direction: row;
    padding-left: 26px;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid #d0d0d0;
    align-items: center;
    width: 100%;
    z-index: 30;
    background: white;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;

    .location-search-input-label{
        font-weight: 500;
        padding-right: 10px;
    }

    .location-search-input{
        width: 250px;
    }
}