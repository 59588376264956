@import "colors";
@import "variables";

.header-option {
    height: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;

    button {
        background-color: transparent;
        border: none;
        padding: 0 16px;
        cursor: pointer;
        height: 1.5rem;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.5px;
        color: #fff;
        .mat-icon {
            margin-left: 2px;
        }

        &:hover {
            color: $maptycs-white;
        }
    }

    map-group-panel {
        position: absolute;
        top: 100%;
    }
}

.nested-menu.header-menu {
    .mat-menu-item {
        display: flex;
        align-items: center;
        padding: 0 0 0 8px !important;
        height: 1.688rem;

        > span {
            min-width: 20px;
            display: flex;

            .mat-icon {
                color: $maptycs-accent;
                width: 13px;
                margin-right: 8px !important;
            }
        }
    }

    .mat-menu-content:not(:empty) {
        padding: 8px 0;
    }
}
