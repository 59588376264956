@import "colors";

.report-container {
    min-height: 250px;
    display: flex;
    justify-content: space-between;
}

.report-header {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding-left: 24px;
    border-bottom: none;

    .header-icon {
        color: $maptycs-accent;
        margin-right: 8px;
        vertical-align: middle;
    }
}

.report-type-icons-container {
    .report-icon {
        position: relative;
        width: 42px;
        text-align: center;
        display: inline-block;
        cursor: pointer;

        &:first-child {
            &:before {
                content: none;
            }
        }

        &:before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $maptycs-grey-border;


        }
    }
}

.report-icon {
    width: 50px;
    text-align: center;
    color: #002d57;

    &.footer {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        width: 42px;

        &.selected {
            background: #9a9898b9;
        }

        &.selectable {
            &:hover {
                background: #9a9898b9;
            }

            cursor: pointer;
        }
    }

    &.active {
        color: $maptycs-black;
    }

    &.selected {
        color: $maptycs-accent;
    }
}

.report-submit-btn {
    padding-left: 0px;
    padding-right: 10px;
    cursor: pointer;
    color: $maptycs-accent;
}

//.report-content {
//    margin-top: 20px;
//}

.report-title {
    width: 100%;
    font-size: 14px !important;
}

.report-remove-icon {
    height: 100%;
    cursor: pointer;
}

.multi-select-btn {
    border-bottom: 1px solid;
    border-radius: 0;
    width: 164px;
}

.report-table {
    width: 100%;
}

.report-instructions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    color: #a2a2a2f5;
    font-size: 0.75rem;
}

.report-table {

    max-height: 300px;
    width: 100%;
    overflow: auto;

    .mat-table {
        background: none;
    }

    tr.mat-header-row {
        height: 36px !important;
        background: whitesmoke;
    }

    tr.mat-footer-row {
        font-weight: 700;
    }

    tr.mat-footer-row,
    tr.mat-row {
        height: 24px !important;
    }

    .mat-cell,
    .mat-footer-cell {
        font-size: 11px !important;
    }

    td.mat-cell,
    td.mat-footer-cell,
    th.mat-header-cell {
        min-width: 70px;
        //max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 10px;
    }
}

.report {
    margin-bottom: 20px;
}

.report-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    background-color: #ffffff;
    
}

.report-title-container {
    display: flex;
    justify-content: flex-end;
}

.report-bottom-title {
    width: 100%;
    text-align: right;
    padding: 4px;
    font-family: ProximaNova;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 700;
    color: black;
    opacity: 0.15;
    border: none;
}