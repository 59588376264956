.tile-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%px;
    height: 256px;
    transition: opacity 200ms linear 0s;

    .tile-image {
        width: 256px;
        height: 256px;
        user-select: none;
        border: 0px;
        padding: 0px;
        margin: 0px;
        max-width: none;
    }

}



