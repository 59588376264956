$toolbar-height: 80px;
$toolbar-row-height: 34.5px;
$formula-height: 40px;
$filters-height: 44px;
$footer-height: 30px;
$breadcrumbs-height: 40px;
$header-height: 60px;
$table-actions-height: 70px;
$table-footer-height: 48px;
$table-footer-paginator-item-size: 32px;
$table-header-height: 32px;
$table-row-height: 30px;
$table-row-icon-size: 18px;
$menu-item-height: 28px;
$menu-item-input-height: 40px;
$font-big: 18px;
$font-medium: 16px;
$font-normal: 13px;
$font-small: 12px;
$font-tiny: 11px;
$button-height-big: 38px;
$button-height-small: 34px;
$dialog-header-height: 50px;
