@import '@angular/material/theming';
@import './palette';

@include mat-core();

$maptycs-primary-pallete: mat-palette($maptycs-primary-pallete-colors);
$maptycs-accent-pallete: mat-palette($maptycs-accent-pallete-colors, 500, 900, A100);
$maptycs-theme: mat-light-theme($maptycs-primary-pallete, $maptycs-accent-pallete);

$custom-typography: mat-typography-config($font-family: '"Inter", sans-serif');

@include mat-core($custom-typography);
@include angular-material-theme($maptycs-theme);

.list-item-active {
    font-weight: bold;
    background-color: mat-color($maptycs-accent-pallete-colors, 500) !important;
    color: #fff !important;
}
