// @font-face {
//     font-family: ProximaNova;
//      src: local(ProximaNova-Regular),
//          url(../fonts/ProximaNova-Regular.otf) format("opentype");
//      font-weight: normal !important;
//      font-style: normal !important;
//      font-stretch: normal !important;
//  }
 
//  @font-face {
//     font-family: ProximaNova;
//      src: local(ProximaNova-Bold),
//      url(../fonts/ProximaNova-Bold.otf) format("opentype");
//      font-weight: bold !important;
//      font-style: normal !important;
//      font-stretch: normal !important;
//  }
 
 .H0 {
    font-family: ProximaNova;
     font-size: 5.625rem;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.08;
     letter-spacing: normal;
     color: var(--black);
 }
 
 h1 {
    font-family: ProximaNova;
     font-size: 3.75rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.27;
     letter-spacing: normal;
     color: var(--black);
 }
 
 h2 {
    font-family: ProximaNova;
     font-size: 2rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.44;
     letter-spacing: normal;
     color: var(--black);
 }
 
 h4 {
    font-family: ProximaNova;
     font-size: 1.375rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.59;
     letter-spacing: normal;
     color: var(--black);
 }
 
 h5 {
    font-family: ProximaNova;
     font-size: 1.125rem;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     color: var(--black);
 }
 
 .TypeLeftBody-Large {
    font-family: ProximaNova;
     font-size: 1.125rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.56;
     letter-spacing: normal;
     color: var(--primary);
 }
 
 h6 {
    font-family: ProximaNova;
     font-size: 1.125rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     color: var(--black);
 }
 
 caption {
    font-family: ProximaNova;
     font-size: 1rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: 3px;
     color: var(--black);
 }
 
 .Body-L {
    font-family: ProximaNova;
     font-size: 1rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.75;
     letter-spacing: normal;
     color: var(--black);
 }
 
 .TypeLeftBody-Small {
    font-family: ProximaNova;
     font-size: 0.75rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.5;
     letter-spacing: normal;
     color: var(--primary);
 }
 
 .Body-M {
    font-family: ProximaNova;
     font-size: 0.75rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.67;
     letter-spacing: normal;
     color: var(--black);
 }
 
 .TypeButtonLight {
    font-family: ProximaNova;
     font-size: 0.625rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 2.8;
     letter-spacing: normal;
     text-align: center;
     color: #fff;
 }
 
 .TypeButtonDark {
    font-family: ProximaNova;
     font-size: 0.625rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 2.8;
     letter-spacing: normal;
     text-align: center;
     color: var(--dark);
 }
 
 .Body-S {
    font-family: ProximaNova;
     font-size: 0.625rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 2;
     letter-spacing: normal;
     color: var(--black);
 }
 
 .TypeLeftInput {
    font-family: ProximaNova;
     font-size: 0.5rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 2.25;
     letter-spacing: normal;
     color: var(--primary);
 }
 
 .Body-XS {
    font-family: ProximaNova;
     font-size: 0.5rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.25;
     letter-spacing: normal;
     text-align: right;
     color: var(--black);
 }