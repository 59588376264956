@import 'colors';
@import 'variables';

.footer-actions {
    height: 40px;
    flex: 0 0 40px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    box-shadow: 0 2px 6px #585858;
    z-index: 101;
    width: 100%;

    .footer-group-container {
        height: 100%;
        display: inline-flex;
    }

    .footer-icon {
        height: 15px;
        width: auto;
    }

    .footer-group {
        height: 100%;
        display: inline-flex;

        &.thematic-map-section {
            // width: calc(100% - 204px);
            width: 80%;
        }

        .footer-label {
            display: flex;
            align-items: center;
            padding: 0 16px;
            white-space: nowrap;
            color: #002d57;
            text-transform: capitalize;
            font-size: 11px;
        }

        .footer-slider {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 24px;
            width: 80px;
        }

        .config-icon {
            padding-right: 20px;
            cursor: pointer;
            height: 20px;
            width: auto;

            &:hover {
                color: $maptycs-accent;
            }
        }

        .footer-floating-fields-container {
            display: flex;
            font-size: 11px;
            font-weight: 600;
            width: auto;
            overflow-y: hidden;
            align-items: center;
            color: #2c2c2c;

            .floating-field {
                border-left: 1px solid #2c2c2c;

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    display: block;
                }
            }
        }

        .footer-thematic-map-container {
            display: flex;
            background: #ffffff;
            align-items: center;
            font-weight: bold;
            color: #002d57;
            border-right: 1px solid #cccccc;
            border-left: 1px solid #cccccc;
        
        }

        .footer-button {
            height: 100%;
            min-width: 60px;
            text-transform: capitalize;
            cursor: pointer;
            user-select: none;
            background-color: transparent;
            border: none;
            transition: 0.3s background-color;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 12px;
            border-right: 1px solid #cccccc;

            &.footer-button-active {
                color: $maptycs-white;
                background-color: $maptycs-accent;

                &:hover {
                    background-color: lighten($maptycs-accent, 8%);
                }
            }

            &:not(.footer-button-active):hover {
                transition: 0.3s background-color;
                background-color: #eaeaea;
            }
        }
    }
}

.mat-slide-toggle-thumb-container {
    cursor: pointer !important;
}

.mat-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 17px) !important;
    left: calc(50% - 17px) !important;
    height: 30px !important;
    width: 30px !important;
}

.footer-option {
    height: 100%;
    position: relative;
    display: inline-flex;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;

    button {
        background-color: transparent;
        border: none;
        text-transform: capitalize;
        color: #002d57;
        font-size: 0.75rem;
        padding: 0 12px;
        cursor: pointer;
        font-weight: 600;
        font-family: ProximaNova;

        .mat-icon {
            margin-left: 2px;
        }

        &:hover {
            background-color: $maptycs-grey-medium;
        }
    }
}