@import "colors";
@import "variables";


.filters-container {
    // main container for filter bar item
    display: flex;
    flex-direction: row;
    padding: 7px 15px;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid #d0d0d0;
    align-items: center;
    width: 100%;
    z-index: 30;
    background: white;
    height: auto;

    button {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 12px;
        padding: 0;
        color: #8c8c8c;
    }

    .filter-count {
        text-transform: capitalize;
        font-weight: normal;
        font-size: 0.75rem;
        padding: 0;
        text-align: left;
        color: #002d57;    

        .overlay-name {
            text-transform: none;
            color: #333;
            font-size: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 10px 0 5px;
        }

        .filter-locations-label{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mat-icon{
                color: $maptycs-accent;
                font-size: $font-medium;
                cursor: pointer;
            }
        }
    }

    .filter-item {
        display: inline-flex;
        padding: 0 20px;
        border-left: 1px solid #d0d0d0;

        .filter-item-content {
            position: relative;
            width: 160px;
            cursor: pointer;

            .filter-title, .filter-value {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 0.75rem;
            }

            .filter-title {
                color: #002d57;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: capitalize;
    margin-right: 8px;
            }

            .filter-close {
                position: absolute;
    right: -8px;
    top: 0;
    font-size: 16px;
    color: #0044f1;
            }
        }

        > button {
            line-height: 24px;
            border-color: $maptycs-accent;
            background-color: $maptycs-white;
            color: $maptycs-accent;
            font-size: 12px;
            padding: 0 8px;
        }

        .mat-icon-button {
            height: 22px;
            line-height: 22px;
            width: 26px;
        }

        .material-icons {
            font-size: 14px;
        }
    }

    .filter-clear-button {
        display: inline-flex;
        margin-left: auto;
    }

    .filter-clear-button mat-icon {
        line-height: 5px;
    }
}

.filters {
    flex-wrap: wrap;
    flex: 1;
}

.sideBarContainer {
    .mat-button-wrapper {
        display: flex !important;
        flex-direction: row !important;
        width: 100% !important;
    }
}

.dateMenuContent, .number-field-content {
    // padding: 20px;
    display: flex;
    flex-direction: column;
}

.date-form-field {
    width: 100%;
}

.ng-switch-short-text-field.highCardinality{
    padding: 20px 16px 8px;
}

.date-apply-button {
    width: 100%;

}

.filter-count {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.apply-filter-button {
    width: 100%;
}

.full-width {
    width: 100%;
}

.partial-width {
    width: 60%;
}

.filter-bar-item-button {
    display: flex;
}

.sideBarContainer {
    display: flex;
    flex-direction: row;
    width: 200px;
}

.sideBar {
    height: 20px;
    z-index: 98;
    flex: 2;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .3s linear;
}

.content {
    flex: 1;
    width: 0;
    transition: all .3s linear;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sideBarContainer.active:hover .sideBar {
    flex: 0;
    width: 0;
    overflow: hidden;
}

.sideBarContainer.active:hover .content {
    flex: 2 0 auto;
    width: 80%;
}

.clear-button {
    margin-left: auto;
    width: fit-content !important;
}

.table-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
}

.side-margins {
    margin: 0 35px;
}

.filter-item {
    margin-bottom: 7px;
    height: 25px;
    padding: 3px 15px !important
}

.filter-bar-item-text-field {
    max-height: 100%;
    overflow: auto;
    display: block;
}


.remove-filter-bar-button {
    display: block;
    margin-left: auto;
}

.disabled-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: grey;
    z-index: 99;
    opacity: 0.4;
}

.apply-button-wrapper {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    border-bottom: 1px solid $maptycs-grey-border;
}

.link-distance-input {
    input {
        padding: 0px 12px;
        position: relative;
        width: 100%;
        outline: none;
        background-color: rgba(0, 0, 0, 0);
        color: rgb(66, 66, 66);
        cursor: inherit;
        font: inherit;
        opacity: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        border: none;
        border-bottom: 1px solid $maptycs-grey-border;
        font-size: $font-small;
        height: $menu-item-input-height;
    }
}

.slider-outer-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
}

.no-values{
    padding: 0 16px;
    font-size: $font-small;
}
