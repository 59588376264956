@font-face {
    font-family: ProximaNova;
     src: local(ProximaNova-Regular),
         url(../fonts/ProximaNova-Regular.otf) format("opentype");
     font-weight: normal !important;
     font-style: normal !important;
     font-stretch: normal !important;
 }
.mat-form-field-invalid {
    .mat-input-element {
        caret-color: #f00000 !important;
    }
}
.mat-form-field.mat-form-field-invalid {
    .mat-form-field-ripple {
        background-color: #f00000 !important;
    }
}
.mat-stroked-button {
    border-radius: 1px !important;
    width: 3.5rem !important;
    height: 1.875rem !important;
    font-family: ProximaNova;
    font-size: .75rem !important;
    padding: 0.063rem 0.75rem !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.8 !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #ffffff !important;
}
// switch button style
::ng-deep .mat-slide-toggle-bar {
    height: 1.188rem !important;
    width: 2.375rem !important;
    padding: 0.125rem 1.25rem 0.125rem 0.188rem;
    border-radius: 10px;
    .mat-slide-toggle-thumb-container {
        position: absolute;
        z-index: 1;
        width: 0.938rem !important;
        height: 0.938rem !important;
        top: 1px !important;
        left: 3px !important;
        transform: translate3d(0, 0, 0);
        transition: all 80ms linear;
        transition-property: transform;
        .mat-slide-toggle-thumb {
            width: 0.938rem !important;
            height: 0.938rem !important;
            border: 1px solid #002d57 !important;
        }
    }
}
::ng-deep .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
        background-color: #ededed !important;
        .mat-slide-toggle-thumb-container {
            position: absolute;
            top: 1px !important;
            left: 11px !important;
            .mat-slide-toggle-thumb {
                background-color: #0044f1 !important;
                border: none !important;
            }
        }
    }
}
// pseudo checkbox
.mat-pseudo-checkbox::after {
    color: #0044f1;
}
.mat-pseudo-checkbox {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.8px;
    border: 0.8px solid #002d57;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 0.271rem 0.194rem 0.244rem 0.179rem;
    position: relative;
    flex-shrink: 0;
    transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1), background-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
    border-color: #002d57;
    border-width: 0.8px !important;
    border-style: hidden;
}
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    border: 0.8px solid #002D57;
    border-radius: 0.8px;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background .mat-checkbox-checkmark-path {
    stroke: #0020f1 !important;
}
.mat-pseudo-checkbox-checked::after {
    top: 3px;
    left: 1.6px;
    width: 6px;
    height: 2px;
    font-weight: normal !important;
    border-left: 2px solid currentColor;
    transform: rotate(-45deg);
    opacity: 1;
    box-sizing: content-box;
    color: #1552ff;
}
.edit-form-container {
    .form-wrapper {
        margin: 0 0 1rem 0.425rem;
        padding: 0.75rem 0.625rem 0 1.063rem;
        background: #ffffff;
    }
    .container {
        margin: 0 0 1rem 0.425rem;
        padding: 0.75rem 0.625rem 0 1.063rem;
        background: #ffffff;
    }
}