@import "colors";
@import "variables";

$color-box-size: 16px;
$colorization-footer-height: 80px;

.colorization-box {
    display: block;
    height: calc(100% - #{$colorization-footer-height});

    .input-label {
        width: 100px;
        display: inline-block;
    }

    mat-icon {
        font-size: 16px;
        color: #08080d;
    }

    mat-form-field {
        width: 132px;

        mat-select {
            .mat-select-value {
                color: rgb(255 255 255 / 0.55);
            }

            .mat-select-arrow-wrapper {
                opacity: 0;
            }

            .mat-select-placeholder {
                color: rgba(0, 0, 0, 0.35);
            }
        }

        .mat-form-field-underline {
            opacity: 0;
        }
    }

    .color-box-container {
        display: inline-flex;
    }

    .color-square {
        cursor: pointer;
        width: $color-box-size;
        height: $color-box-size;
        display: inline-block;
        margin-left: 8px;
        position: relative;

        .remove-color-icon {
            position: absolute;
            top: -4px;
            right: -4px;
            background-color: red;
            width: 12px;
            height: 12px;
            font-size: 14px;
            border-radius: 100%;
            color: #fff;
            display: none;
            justify-content: center;
            align-items: center;

            mat-icon {
                font-size: 10px;
                height: 10px;
                width: 10px;
            }
        }

        &.add-color-icon {
            border: 1px solid #6f6f6f;
            display: inline-flex;

            mat-icon {
                width: $color-box-size;
                height: $color-box-size;
                line-height: $color-box-size;
                font-size: 13px;
            }
        }

        &:hover {
            .remove-color-icon {
                display: flex;
            }
        }

    }

    .inline-label {
        >span {
            padding-right: 8px;
        }
    }

    .row {
        margin-bottom: 8px;
    }

    .colorization-footer {
        display: flex;
        //height: $colorization-footer-height;
        padding: 0px 14px;
        align-items: flex-end;

        .colorization-footer-extra {
            display: inline-flex;
            flex: 1;
        }

        button {
            line-height: 24px;
            background-color: $maptycs-accent;
            color: $maptycs-white;
            font-size: 12px;
            padding: 0 8px;
            border-radius: 0;
            height: 30px;
            width: 80px;
            position: absolute;
            bottom: 12px;
            right: 12px;
        }
    }

    .color-interval-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 8px;

        input {
            width: 228px;
            height: 24px;
            border-bottom: 2px solid #ffffff;
            background: #002d57;
            color: #ffffff;
            border-radius: 2px;
            padding: 0 4px;
        }

        mat-icon {
            cursor: pointer;
            font-size: 20px;
            height: 20px;
            width: 20px;
        }
    }

    .color-interval-container {
        height: calc(100% - 40px - 40px);
        width: 100%;

        .color-interval-content {
            height: calc(100% - 28px);
            overflow-y: auto;
            background-color: rgba(0, 0, 0, 0.35);
            width: 100%;
            margin: 6px 0;
        }
    }

    .color-interval-row {
        display: flex;
        align-items: center;
        width: 100%;
        max-height: 40px;
        min-height: 40px;
        color: #002d57;

        .color-square {
            margin-top: 2px;
            margin-right: 8px;
        }
    }

    mat-form-field {

        .mat-form-field-wrapper {
            margin-bottom: -1.25em;
        }

        .mat-form-field-infix {
            border: none;
        }

    }

    #colorizeByField {
        width: 132px;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        color: #002d57;
        font-size: 0.85rem;
        font-weight: 500;
        padding-top: 0px;
    }

    .colorization-config-box-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 15px;

        >button {
            height: 30px;
            border-radius: 2px;
            width: 130px;
            line-height: 30px;
        }
    }
}


.mat-tooltip {
    font-size: 0.75rem;
    padding: 0.5rem !important;
    border-radius: 2px !important;
    max-width: 250px !important;
    max-height: 400px !important;
    overflow: auto !important;
    // min-width: 5rem !important;
    // height: 1.875rem;
    // display: flex !important;
    // align-items: center !important;
    // justify-content: center;
    margin: 12px 12px 12px 12px !important;
    mix-blend-mode: multiply;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
}

.colorization-hint {
    color: #002d57;
    font-size: 10px;
    padding: 4px 15px 0;
    text-align: center;
}

.colorization-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 8px;
    width: 100%;

    .colorization-cell {
        border-bottom: 1px solid rgba(0, 0, 0, 0.35);
        padding: 0px 16px 4px;
    }
}

.colorization-label {
    color: #002d57;
    font-size: $font-tiny;
    font-weight: 600;
    text-transform: capitalize;
    margin-right: 4px;

    .mat-icon {
        font-size: $font-tiny;
    }
}

.colorization-etc {
    padding: 8px 16px;

    .color-box-container {
        padding-top: 6px;

        >div {
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

.colorization-scroll {
    flex-grow: 1;
    overflow: auto;
}
