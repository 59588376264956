@import "colors";

html,
body {
    height: 100%;
    background-color: $maptycs-background;
}

body {
    margin: 0;
    font-family: ProximaNova;
    color: $maptycs-font-color;
}

* {
    box-sizing: border-box;
}

ul {
    list-style-type: none;
    padding: 0;
}
