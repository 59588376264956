.mat-datepicker-content-container {
    width: 26.75rem;
    // height: 22.563rem;
    padding: 1.5rem 1.76rem 2.25rem 2.198rem;
    border-radius: 2px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.21);
    background-color: #ffffff;

    .mat-calendar {
        width: 22.792rem !important;

        .mat-button-wrapper {
            font-family: ProximaNova !important;
            font-size: 1rem !important;
            font-weight: bold !important;
            font-stretch: normal !important;
            font-style: normal !important;
            line-height: 1.75 !important;
            letter-spacing: normal !important;
            color: #08080d !important;
        }

        .mat-calendar-header {
            padding: 0;
            width: 22.792rem;

            .mat-calendar-controls {
                .mat-button {
                    padding: 0;
                }

                /* .mat-icon-button.mat-button-disabled.mat-button-disabled {
            color: #0f4ff1;
          }
  
          .mat-datepicker-content .mat-calendar-next-button {
            color: #0f4ff1;
          } */

                .mat-calendar-previous-button::after {
                    border-left: 2px solid #0f4ff1 !important;
                    border-top: 2px solid #0f4ff1;
                }

                .mat-calendar-next-button::after {
                    border-right: 2px solid #0f4ff1 !important;
                    border-top: 2px solid #0f4ff1;
                }
            }
        }

        .mat-calendar-content {
            width: 20.792rem;
            padding-left: 16px !important;

            .mat-calendar-table {
                .mat-calendar-table-header {
                    .mat-calendar-body-cell-content {
                        color: #002d57 !important;
                    }

                    tr {
                        th {
                            span {
                                color: #08080d;
                                margin: 0 0 1.813rem;
                                font-family: ProximaNova;
                                font-size: 0.75rem;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.67;
                                letter-spacing: normal;
                                text-align: center;
                            }

                            ::ng-deep .mat-calendar-table-header-divider {
                                &after {
                                    background: #0044f1 !important;
                                    left: -1px !important;
                                    width: 22.792rem;
                                }
                            }

                            td {
                                .mat-focus-indicator {
                                    color: #002d57 !important;
                                }

                                .mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
                                    border: 2px solid #0044f1 !important;
                                    color: #0044f1 !important;
                                    top: 5%;
                                    left: 5%;
                                    z-index: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    box-sizing: border-box;
                                    width: 2.063rem;
                                    height: 2.063rem;
                                    line-height: 1;
                                    border-width: 1px;
                                    border-style: solid;
                                    object-fit: contain;
                                    border-radius: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mat-calendar-body-cell-content {
    color: #002d57 !important;
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border: 2px solid #0044f1 !important;
    color: #0044f1 !important;
    border-radius: 2px;
}

::ng-deep .mat-calendar-table-header-divider::after {
    background: #0044f1 !important;
    left: -1px !important;
    width: 22.792rem !important;
}

.mat-calendar-table-header-divider::after {
    background: #0044f1 !important;
    left: -1px !important;
    width: 22.792rem !important;
}

::ng-deep .mat-calendar-body-selected {
    background-color: #ffffff !important;
    border: 2px solid #0044f1 !important;
    color: #0044f1 !important;
    border-radius: 2px !important;
}

.mat-calendar-body-cell:hover {
    background-color: #ffffff !important;
}

.mat-calendar-body-cell-content:hover {
    border-radius: 2px !important;
}

.mat-calendar-body-cell-content {
    border-radius: 2px !important;
    background: #ffffff !important;
}

.mat-calendar-body-disabled {
    .mat-calendar-body-cell-content {
        color: #a3d3ff !important;
    }
}