@import "@angular/material/theming";
@import "styles/reset";
@import "styles/colors";
@import "styles/panels";
@import "styles/toolbar";
@import "styles/map";
@import "styles/material";
@import "styles/tree";
@import "styles/table";
@import "styles/footer-actions";
@import "styles/filters";
@import "styles/nested-menu";
@import "styles/reports";
@import "styles/colorization-box";
@import "styles/breadcrumbs";
@import "styles/inputs";
@import "styles/buttons";
@import "styles/dialog";
@import "styles/header-menu";
@import "styles/google-map";
@import "styles/legends";
@import "styles/expansion-panel";
@import "styles/ag-grid";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "styles/global.scss";
@import "styles/sizes/index";
@import "styles/date-picker.scss";
@import "styles/hazard-colors/hazard-colors.scss";
@import "styles/font-style/font-style.scss";

// @media only screen and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
//     body {
//         font-family: ProximaNova;
//         font-size: 1.25rem;
//     }
// }

body {
    font-family: ProximaNova;
}

.page-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #ededed;

    .page {
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex-grow: 1;

        .page-container {
            background-color: #ececec;
            display: flex;
            flex-direction: column;
            padding: 0 0.625rem 0.688rem 0.625rem;
            overflow: hidden;
            margin: 0;
            flex-grow: 1;
            min-height: 0;

            .page-content {
                flex-grow: 1;
                min-height: 0;
            }

            .table-content {
                overflow: auto;

                table {
                    width: 100%;
                }
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.mat-dialog-container {
    position: relative;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #3372b9 !important;
    border-radius: 0 !important;
    padding-top: 0 !important;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    color: white !important;
    top: 65% !important;
}

*:focus {
    outline: none;
}

.flex {
    display: flex;
}

.flex.column {
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #7fa1f8;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #002d57 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lighten(#23323e, 5%);
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.1);
}

.text-capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.spinnder-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.mat-slider-horizontal {
    height: 30px !important;

    .mat-slider-wrapper {
        top: 14px !important;
    }
}

.display-flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.distance {
        mat-form-field {
            width: 100%;
            margin-bottom: 0 !important;

            .dropdown-medium .mat-select-trigger .mat-select-value {
                top: 0;
            }
        }

        .mat-form-field-appearance-outline {
            padding-top: 0 !important;
        }
    }

    &.flex-end {
        justify-content: flex-end;
    }
}

.readonly {
    pointer-events: none;
    user-select: none;
}

.agm-info-window-content {
    font-size: 0.85rem;
    font-family: ProximaNova;

    .title {
        font-weight: 500;
        font-size: 15px;
        color: #717171;
        font-family: ProximaNova;
    }

    .tooltip-subtitle {
        margin-top: 4px;
        margin-bottom: 4px;
        color: #0044f1;
        font-weight: 600;
    }

    .tooltip-value {
        margin-left: 5px;
    }
}

.mat-horizontal-content-container {
    overflow-y: auto !important;
}

// flex layout core styles, used in datapoints, drafts, and overlays

.flex-view {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
}

.flex-top-bar {
    flex: 0 1 auto;
}

.flex-middle-content {
    overflow-y: auto;
    flex: 1 1 auto;
}

.view-cont {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1 1 auto;
}

.side-margins {
    margin: 0 35px;
}

// flex layout core styles, used in datapoints, drafts, and overlays

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.hidden {
    visibility: hidden !important;
}

.spinner-container {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.white-color .mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #ffffff;
}

.content-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.color-blue {
    color: $maptycs-accent;
}

.ag-full-width-container {
    > div {
        height: auto !important;
    }
}

// sub menu css added starts

.mat-menu-panel {
    min-width: 10.813rem !important;
    max-width: 10.813rem !important;
    overflow: auto !important;
    padding: 0.525rem 0.936rem;

    button.mat-menu-item {
        width: 100%;
        height: 1.688rem;
        margin: 0px 0 0.563rem;
        padding: 0.438rem 0rem 0 0;
        font-size: 0.75rem;
        line-height: 0;
        color: #08080d;
    }
}

.sub-menu-options-child {
    width: 10.813rem;
}

.external-sub-menu {
    ul {
        min-width: 8.813rem;
    }

    .mat-tree-node {
        flex-direction: row !important;
        justify-content: space-between;
    }
}

.external-sub-menu li .mat-tree-node {
    min-height: 25px;
    width: 8.813rem !important;
}

.external-sub-menu li ul {
    padding-left: 0 !important;
}

::ng-deep .mat-tree {
    width: 8.813rem !important;
}

.sub-menu-options-parent {
    ul {
        margin: 0;
        min-width: 8.813rem;
    }

    li {
        list-style: none;
        overflow: unset;

        .mat-tree-node {
            min-height: 25px;
            width: 8.813rem;
            justify-content: space-between;

            li {
                display: flex;
                flex-direction: row;
                gap: 5px;
                width: 8.813rem;
            }

            span {
                font-family: ProximaNova;
                font-size: 0.75rem;
                color: rgba(0, 0, 0, 0.87);
                line-height: 25px !important;
            }

            .workspace-item-menu-option {
                &:hover {
                    background: rgba(0, 0, 0, 0.15) !important;
                }
            }

            .external-node-title {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 5.813rem;
            }

            .filter-inner-parent {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 5.813rem;
                font-weight: 600;
            }
        }

        .mat-icon-button {
            width: 25px;
            height: 25px;
            line-height: 25px;
        }

        .sub-menu-form-options {
            gap: 10px;
            padding: 0 10px;

            .link-distance-input {
                width: 70px;
                border-radius: 1px;
                border: 0.8px solid #002d57;
                font-size: 0.75rem;
            }
        }

        ul {
            padding-left: 5px;
        }
    }

    .global-filter ul {
        padding-left: 5px !important;
    }

    .sub-menu-form-options {
        padding: 0 !important;
        border-bottom: 1px solid #ccc;
        margin: 0 5px !important;

        .apply-button-wrapper {
            font-size: 0.75rem;
            border: 1px solid #0044f1;
            background: #0044f1;
            color: #ffffff;
            border-radius: 1px;
            height: 1.875rem;
            padding: 0.063rem 0.75rem;

            span {
                color: #ffffff;
            }
        }
    }

    .sub-menu-checkbox-options {
        .mat-icon-button {
            display: none;
        }
    }

    .mat-button-wrapper {
        .mat-icon {
            font-size: 20px;
        }
    }
}

.sub-menu-form-options-tree {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

// sub menu css added end

// map new css
.gm-style .gm-style-iw-c {
    border-radius: 2px !important;
    padding: 12px 8px 12px 8px !important;
    margin: 0.475rem !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar {
    width: 0.188rem !important;
}

.gm-style .gm-style-iw-d {
    padding: 8px 8px 0 8px !important;
    margin-top: 4px;
}

.gm-style .gm-style-iw-tc::after {
    background: transparent !important;
}

.agm-info-window-content {
    font-size: 0.85rem;

    .list-item {
        cursor: pointer;
        border-bottom: 0.8px solid #d9d9d9;
        display: flex;
        justify-content: space-between;
        min-width: 200px;
        font-family: ProximaNova;
        font-size: 0.75rem;
        line-height: 1.8;
    }
}

.agm-info-window-content .title {
    font-weight: 500;
    font-size: 0.9rem;
    color: #002d57;
    font-family: ProximaNova;
}

.agm-info-window-content ul {
    margin: 6px 0 6px 0;
}

.agm-info-window-content .mat-raised-button {
    box-shadow: none;
    color: #ffffff;
    font-family: ProximaNova;
    font-size: 0.75rem;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    height: 1.875rem;
    padding: 0.063rem 0.75rem;
    border-radius: 1px;
    background-color: #0044f1;
}

// New css
.mat-checkbox {
    font-family: ProximaNova !important;
}

.mat-button-toggle {
    font-family: ProximaNova !important;
}

.mat-menu-item {
    font-family: ProximaNova !important;

    &:hover {
        background: rgba(0, 0, 0, 0.15) !important;
    }
}

.mat-slide-toggle-content {
    font-family: ProximaNova !important;
}

.mat-table {
    font-family: ProximaNova !important;
}

.mat-select {
    font-family: ProximaNova !important;
}

.gm-style {
    font-family: ProximaNova !important;
}

.mat-form-field {
    font-family: ProximaNova !important;
}

.mat-card {
    font-family: ProximaNova !important;
}

.mat-tooltip {
    font-family: ProximaNova !important;
}

.mat-option {
    font-family: ProximaNova !important;
}

.ag-root-wrapper-body.ag-layout-normal {
    height: 100% !important;
}

// dropdown modal
::ng-deep .mat-option-text {
    color: #002d57;
    font-size: 0.75rem;
    line-height: 1.25rem;
}

::ng-deep .mat-select-panel {
    border-radius: 2px;
}

::ng-deep .mat-select-panel .mat-option {
    height: 1.688rem !important;
    border-bottom: 1px solid #eaeaea;
}

::ng-deep .mat-select-trigger {
    position: relative !important;
    bottom: 8px !important;
}

.location-address-select {
    position: relative !important;
    top: 7px !important;

    ::ng-deep .mat-select-arrow-wrapper {
        height: 0px !important;
    }
}

.address-profile-address-select {
    ::ng-deep.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
        position: relative !important;
        top: 8px !important;
    }
}

::ng-deep .mat-select-arrow {
    position: relative;
    top: 8px;
}

.mat-form-field-appearance-fill .mat-form-field-ripple {
    bottom: 0;
    height: 1px !important;
}

// radio button
.mat-radio-button {
    font-size: 0.75rem;
    padding-right: 20px;
}

::ng-deep .mat-radio-container {
    width: 0.75rem !important;
    height: 0.75rem !important;
}

::ng-deep .mat-radio-outer-circle {
    width: 0.75rem !important;
    height: 0.75rem !important;
    padding: 0.188rem !important;
    border-radius: 6px !important;
    border: solid 0.8px #0044f1 !important;
}

::ng-deep .mat-radio-inner-circle {
    height: 0.6rem !important;
    left: 0px !important;
    position: absolute;
    top: 0px !important;
    width: 0.6rem !important;
    padding: 6px !important;
}

// new icons
.icon-container-wrap svg {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    margin: 0 0.225rem;
}

.icon-container-wrap:hover .icon-primary {
    stroke: #0044f1 !important;
}

.icon-container-wrap .dashboard-icon {
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
    margin: 0 0.225rem;
}

.footer-actions
    .footer-group
    .footer-button.footer-button-active
    .icon-primary {
    stroke: #ffffff !important;
}

// for breadcrumb add btn and field download and upload btn
.mat-flat-button.mat-primary .mat-button-wrapper {
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
}

// for breadcrumb save btn
button.mat-flat-button span {
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
}

// for input fields
::ng-deep .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #002d57 !important;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
    color: #002d57 !important;
}

.icon-actions:hover {
    stroke: #0044f1;
}

// checkbox new

::ng-deep .mat-checkbox-inner-container {
    height: 0.75rem !important;
    width: 0.75rem !important;
    padding: 0.271rem 0.194rem 0.244rem 0.179rem;

    .mat-checkbox-background {
        border: 0.8px solid #002d57;
        border-radius: 0.8px;
    }

    .mat-checkbox-frame {
        border-color: #002d57;
        border-width: 0.8px !important;
        border-style: hidden;
    }
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #ffffff;
    border: 0.8px solid #0044f1;
    border-radius: 0.8px;

    .mat-checkbox-checkmark {
        fill: #0044f1;
    }

    .mat-checkbox-checkmark-path {
        stroke: #0044f1 !important;
    }
}

::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #ffffff;
    border: 0.8px solid #0044f1;
    border-radius: 0.8px;

    .mat-checkbox-mixedmark {
        background-color: #0044f1;
    }
}

// custom tooltip
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 11.437rem;
    max-width: 24rem;
    white-space: nowrap;
    height: 1.875rem;
    color: #ffffff !important;
    border-radius: 1px;
    padding: 0.312rem;
    line-height: 1.8;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: capitalize;
    text-align: left;
    position: absolute;
    z-index: 9999;
}

.tooltip .secondary-tooltiptext {
    background-color: #002d57;
}

.tooltip .primary-tooltiptext {
    background-color: #0044f1;
}

.tooltip-top {
    bottom: 145%;
    left: 50%;
    margin-left: 0px;
}

.tooltip-top-left {
    bottom: 116%;
    left: 50%;
    margin-left: 0px;
    transform: translateX(-95%);
}

.tooltip-right {
    top: 48%;
    left: 98%;
    transform: translateY(-50%);
}

.tooltip-left {
    top: 48%;
    right: 100%;
    transform: translateY(-50%);
}

.tooltip-bottom-right {
    top: 126%;
    left: 273%;
    transform: translateX(-50%);
}

.count-tooltip-bottom-right {
    top: 126%;
    left: 200%;
    transform: translateX(-20%);
}

.tooltip-filter {
    bottom: 128%;
    left: 0%;
    margin-left: 0px;
}

.tooltip-bottom-left {
    top: 140%;
    left: 0%;
    transform: translateX(-96%);
}

.secondary-tooltip-bottom-left {
    top: 140%;
    left: 30%;
    transform: translateX(-96%);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.path-top {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 0px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 13px solid #0044f1;
    transform: rotate(180deg);
    position: relative;
    bottom: 26px;
    right: 5px;
}

.menu-path-top {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 0px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 13px solid #0044f1;
    transform: rotate(180deg);
    position: relative;
    bottom: 30px;
    right: 5px;
}

.path-top-left {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 8px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 13px solid #0044f1;
    transform: rotate(180deg);
    position: relative;
    bottom: 26px;
    right: 0px;
    left: 170px;
}

.path-bottom {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 12px solid #0044f1;
    transform: rotate(360deg);
    position: relative;
    bottom: 68px;
    right: 0;
    left: 80px;
}

.path-bottom-right {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 8px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 12px solid #0044f1;
    transform: rotate(360deg);
    position: relative;
    bottom: 66px;
    right: 0;
    left: -5px;
}

.path-bottom-left {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 0px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 12px solid #0044f1;
    transform: rotate(360deg);
    position: relative;
    bottom: 67px;
    right: 0;
    left: 170px;
}

.secondary-path-bottom-left {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 0px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 12px solid #002d57;
    transform: rotate(360deg);
    position: relative;
    bottom: 67px;
    right: 0;
    left: 181.2px;
}

.location-tooltip-top-left {
    bottom: 116%;
    left: 50%;
    margin-left: 0px;
    transform: translateX(-88%);
}

.location-path-top-left {
    width: 0.446rem;
    height: 0.807rem;
    margin: 1.756rem 10.992rem 0 0;
    border-radius: 0px;
    border-right: 8px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 13px solid #0044f1;
    transform: rotate(180deg);
    position: relative;
    bottom: 26px;
    right: 0px;
    left: 181px;
}

// slider
::ng-deep .mat-slider-horizontal .mat-slider-track-background {
    height: 0.5rem !important;
    background-color: #7fa1f8 !important;
    border-radius: 4px !important;
}

::ng-deep .mat-slider-horizontal .mat-slider-track-fill {
    height: 0.5rem !important;
    background-color: #002d57 !important;
    border-radius: 4px !important;
}

::ng-deep .mat-slider-thumb {
    position: absolute;
    right: -10px;
    bottom: -3px !important;
    box-sizing: border-box;
    width: 1.125rem !important;
    height: 1.125rem !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
    background-color: #002d57 !important;
}

::ng-deep .mat-slider-horizontal {
    height: 1.125rem !important;
}

::ng-deep .mat-slider-horizontal .mat-slider-wrapper {
    height: 1.125rem !important;
    top: 5px !important;
}

::ng-deep .mat-slider-horizontal .mat-slider-track-wrapper {
    height: 1.125rem !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar {
    width: 0.188rem;
    height: 0.188rem;
    -webkit-appearance: none;
}

// new cards css

.right-report-card {
    // width: 26.25rem;
    height: 19.5rem;
    padding: 0.5rem 0.938rem 1.25rem 0.938rem;
    border-radius: 2px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.21);
    background-color: #f5f5f5;

    .header-report {
        display: flex;
        justify-content: space-between;

        .right-header-title {
            display: flex;
            align-items: flex-end;
            font-size: 0.85rem;
            font-weight: bold;
            color: #0044f1;
        }

        .right-header-icon {
            svg {
                width: 1.5rem;
                height: 1.5rem;
                color: #0044f1;
            }
        }
    }

    .report-sub-header {
        font-size: 0.75rem;
        height: 0.625rem;
        margin-top: 0.25rem;
    }

    .report-body {
        display: flex;
        justify-content: space-between;
        // margin-top: 0.25rem;
        height: calc(100% - 24px);

        .report-body-content {
            width: 90%;
            margin-top: auto;
            margin-bottom: auto;
        }

        .report-body-menu {
            width: 10%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: flex-end;

            .report-icon-wrap {
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 2px;
                text-align: center;
                background: #d9d9d9;
                margin-bottom: 0.313rem;

                .icon-wrapper {
                    width: 1rem;
                    padding: 2px;
                    height: 1rem;
                    color: #002d57;
                }
            }
        }
    }
}

// Internal Buttons
::ng-deep .mat-flat-button.mat-primary .mat-button-wrapper {
    color: #002d57 !important;
}

.mat-flat-button.mat-accent .mat-button-wrapper {
    color: #ffffff !important;
}

// Disabled input fields
::ng-deep .mat-form-field-disabled .mat-form-field-flex {
    background-color: #eaeaea !important;
}

::ng-deep .mat-form-field-disabled .mat-form-field-flex .mat-input-element {
    color: #002d5750 !important;
}

// global styles
.fs-normal {
    font-size: 0.75rem;
}

.text-lightdark {
    color: #08080d;
}

.text-primary {
    color: #0044f1;
}

.menu-line-separator {
    border: 1px solid #eaeaea !important;
    margin: 2px 0 !important;
}

.group-menu {
    min-width: 13rem !important;
    max-width: 13rem !important;
}

.group-checkbox-label {
    font-size: 0.75rem !important;
}

// Heatmap styles
.heatmap-mat-tree-container {
    min-width: 350px !important;

    .heatmap-mat-tree {
        width: 100% !important;
    }

    ::ng-deep .mat-checkbox-layout .mat-checkbox-label {
        width: 100% !important;
    }
}

// analytics styles
.analytics-mat-tree-container {
    min-width: 300px !important;

    .analytics-mat-tree {
        width: 100% !important;
    }

    ::ng-deep .mat-checkbox-layout .mat-checkbox-label {
        width: 100% !important;
    }
}

// Report tree
.renewal-report-mat-tree-container {
    min-width: 230px !important;
    top: -36px;
    .renewal-report-mat-tree {
        width: 100% !important;
    }

    ::ng-deep .mat-checkbox-layout .mat-checkbox-label {
        width: 100% !important;
    }
}

.custom-recaptcha-page .grecaptcha-badge {
    top: 120px !important;
    bottom: auto !important;
    left: auto !important;  
    width: 70px;
    overflow: hidden;
    transition: width 0.3s ease;
    &:hover {
      width: 256px;
    }
}