@import "variables";
@import "colors";

.ag-page-root {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ag-page {
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex-grow: 1;

        .ag-page-container {
            background-color: #f8f8f8;
            display: flex;
            flex-direction: column;
            padding: 10px;
            overflow: hidden;
            flex-grow: 1;
            min-height: 0;

            .ag-page-content {
                flex-grow: 1;
                min-height: 0;
            }

            .ag-table-content {
                overflow: auto;

                table {
                    width: 100%;
                }
            }
        }

        .grey-background {
            background: url('/assets/images/bg-img.jpg');
        }
    }
}

.ag-toolbar-container {
    display: inline-flex;

    button {
        margin-left: 8px;
    }
}

.ag-toolbar-container .mat-slide-toggle {
    padding: 10px 5px 5px 15px;
}

.ag-toolbar-title {
    font-size: $font-big;
}

.ag-table-actions {
    display: flex;
    height: $table-actions-height;
    flex: 0 0 $table-actions-height;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .filter-input {
        font-size: 14px;
        width: 300px !important;
        max-width: 100%;
    }
}

.ag-table-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: 0px 1.6px 3.6px rgba(0, 0, 0, 0.13), 0px 0px 2.9px rgba(0, 0, 0, 0.11);
    overflow: hidden;

    .ag-table-content {
        height: 100%;
        background-color: #fff;
        overflow: auto;
        width: 100%;

        table {
            width: 100%;

            thead {
                tr.mat-header-row {
                    height: $table-header-height;
                    border-bottom: 1px solid #e2e2e2;

                    th {
                        font-size: 12px;
                        text-transform: capitalize;
                        white-space: nowrap;
                        //box-shadow: 2px 1.6px 3.6px rgba(0, 0, 0, 0.13), 4px 0px 2.9px rgba(0, 0, 0, 0.11);
                        background-color: $maptycs-table-header;
                    }
                }
            }


            tbody {
                tr {
                    td {
                        height: $table-row-height;

                        &.cell-select {
                            padding-right: 16px;
                        }
                    }

                    &:nth-child(odd) {
                        background-color: $maptycs-table-odd;
                    }

                    &:nth-child(even) {
                        background-color: $maptycs-table-even;
                    }
                }
            }

            span {
                .mat-icon {
                    width: auto;
                    height: $table-row-icon-size;
                    color: $maptycs-table-icons;
                    cursor: pointer;
                    outline: none;

                    &:hover {
                        color: $maptycs-accent;
                    }
                }

                &.disabled {
                    .mat-icon {
                        pointer-events: none;
                        color: $maptycs-table-icons-disabled;
                    }
                }
            }

            .ag-table-cell-icons {
                >span {
                    position: relative;
                    padding: 0 12px;

                    &:before {
                        content: "";
                        position: absolute;
                        height: 28px;
                        width: 1px;
                        bottom: -2px;
                        left: 0;
                        background-color: #dddfe1;

                    }

                    &:first-child {
                        padding-left: 0;

                        &:before {
                            content: none;
                        }
                    }
                }
            }

            .mat-header-cell {
                font-size: 12px;
                color: #2d2d2d;
            }
        }


    }

    .ag-tooltip{
        background-color: #002d57;
        color: #ffffff !important;
     }
}

table tr.mat-footer-row,
tr.mat-row {
    height: 32px !important;
}
