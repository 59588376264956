@import '@angular/material/theming';
@import "colors";
@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
$maptycs-primary-pallete-colors: (
    50: $maptycs-primary,
    100: $maptycs-primary,
    200: $maptycs-primary,
    300: $maptycs-primary,
    400: $maptycs-primary,
    500: $maptycs-primary,
    600: $maptycs-primary,
    700: $maptycs-primary,
    800: $maptycs-primary,
    900: lighten($maptycs-primary, 35),
    A100: $maptycs-primary,
    A200: $maptycs-primary,
    A400: $maptycs-primary,
    A700: $maptycs-primary,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);

$maptycs-accent-pallete-colors: (
    50: $maptycs-accent,
    100: $maptycs-accent,
    200: $maptycs-accent,
    300: $maptycs-accent,
    400: $maptycs-accent,
    500: $maptycs-accent,
    600: $maptycs-accent,
    700: $maptycs-accent,
    800: $maptycs-accent,
    900: $maptycs-main,
    A100: $maptycs-accent,
    A200: $maptycs-accent,
    A400: $maptycs-accent,
    A700: $maptycs-accent,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);



//
//$maptycs-white: (
//    50 : #fdfdfe,
//    100 : #fbfbfd,
//    200 : #f8f8fb,
//    300 : #f5f5f9,
//    400 : #f2f3f8,
//    500 : #f0f1f7,
//    600 : #eeeff6,
//    700 : #ecedf5,
//    800 : #e9ebf3,
//    900 : #e5e7f1,
//    A100 : #ffffff,
//    A200 : #ffffff,
//    A400 : #ffffff,
//    A700 : #ffffff,
//    contrast: (
//        50 : #000000,
//        100 : #000000,
//        200 : #000000,
//        300 : #000000,
//        400 : #000000,
//        500 : #000000,
//        600 : #000000,
//        700 : #000000,
//        800 : #000000,
//        900 : #000000,
//        A100 : #000000,
//        A200 : #000000,
//        A400 : #000000,
//        A700 : #000000,
//    )
//);
//
