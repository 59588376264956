@import "variables";

button.mat-flat-button {
    font-family: ProximaNova, "Helvetica Neue", sans-serif;
    height: 1.875rem !important;
    padding: 0.063rem 0.75rem;
    border-radius: 1px;
    background-color: #1552ff;
    line-height: 0;

    span {
        height: 1.75rem;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        text-transform: capitalize !important;
    }

    .mat-icon {
        width: 0.875rem;
        height: 0.875rem;
        margin: 0.187rem 0.295rem 0.187rem 0;
    }

    &.small {
        height: $button-height-small;
    }
}

.mat-button-toggle-checked {
    background-color: #1f355a !important;
    color: white !important;
}

.actions-button {
    position: absolute;
    right: 7px;
    bottom: 0;
    z-index: 100;
    padding: 11px 13px;
    font-size: 15px;
    opacity: 0.8;
    cursor: pointer;
    transition: ease-in background-color 0.2s;

    &:hover {
        background-color: #ececec;
    }
}

.mat-flat-button.mat-accent {
    background-color: #1552ff !important;
}

.mat-flat-button.mat-primary {
    color: $maptycs-main !important;
}
.mat-flat-button.mat-accent {
    color: #ffffff !important;
}
.mat-flat-button.mat-primary.mat-button-disabled{
    background-color: rgb(217 217 217 / 50%) !important;
    color: rgb(0 45 87 / 60%) !important;
}