$space: 12px;

.legend {
    margin-right: 15px;
    height: auto;
    display: flex;
}

.legend-title {
    font-size: 12px;
    color: #ffffffda;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;

    .legend-title-field{
        color: #ffffff6b;
        font-size: 11px;
        margin-top: 5px;
        width: max-content;
    }

    .legend-title-dataset{
        width: max-content;
    }
}

.inner-legend {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    padding: $space;
    position: relative;
    margin-bottom: 10px;
    background-color: #011b31db;
}

.legend-row {
    display: flex;
    height: $space;
    margin-bottom: 10px;
}

.legend-color-box {
    margin-right: $space;
    line-height: $space;
}

.range {
    color: #ffffffda;
    line-height: $space;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
}

.legend-icon-box {
    padding-top: $space;
    padding-bottom: $space;
    padding-right: $space;
    width: 10%;
    display: flex;
    flex-direction: column;
}

.legend-edit-icon {
    color: rgba(255, 255, 255, 0.6);
    margin-top: auto;
    margin-left: auto;
    display: block;
    height: 25px;
}

.color-square {
    font-size: $space;
    width: $space;
    height: $space;
    border: 1px solid #ffffffda;
}

.gradient-square {
    width: 35px;
    height: 100px;
    border: 1px solid #ffffffda;
}

.gradient-values {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.divider {
    margin-left: 6px;
    margin-right: 6px;
}

.legend-row:last-child {
    margin-bottom: 0;
}

.legends-container {
    display: flex;
    position: fixed;
    left: 86px;
    bottom: 0;
    height: 200px;
    margin-bottom: 30px;
    overflow-x: auto;
    margin-left: 34px;
    align-items: center;
    padding-left: 1em;
    padding-right: 2em;
    transition: margin-left 0.5s ease 0s;
    scrollbar-width: none;
    white-space: nowrap;
}

.close-legend{
    cursor: pointer;
    font-size: 10px;
    color: #d7dbdeb8;
    padding-top: 2px;
    padding-left: 8px;
}
