@import "_scss-config";
@import "../styles/hazard-colors/hazard-colors";

$maptycs-primary: $config-primary;
$maptycs-accent: $config-accent;
$maptycs-main: $config-main;
$maptycs-primary-light: lighten($config-primary, 8%);
$maptycs-accent-light: lighten($config-accent, 8%);
$maptycs-main-dark: #08080d;

$maptycs-black: #333;
$maptycs-white: #ffffff;
$maptycs-grey-dark: #3F4756;
$maptycs-grey-light: #f3f3f3;
$maptycs-grey-medium: #e4e4e4;
$maptycs-grey: #666;

$maptycs-grey-border: #e2e2e2;
$maptycs-grey-border-darker: darken($maptycs-grey-border, 10%);

$maptycs-background: #ededed;
$maptycs-font-color: #002d57;

$maptycs-table-header: #ffffff;
$maptycs-table-even: #f9f9f9;
$maptycs-table-odd: $maptycs-white;
$maptycs-table-icons: #7d838c;
$maptycs-table-icons-disabled: #b5b9c1;

$maptycs-toolbar: #545e70;
$maptycs-toolbar-separator: #8c939f;
