@import "colors";
@import "variables";

.dialog {
    display: flex;
    flex-direction: column;
    width: 525px;
    min-height: 200px;
    border-radius: 2px;
    box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.13), 0px 0px 2.9px rgba(0, 0, 0, 0.11);
    overflow: hidden;

    .dialog-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $dialog-header-height;
        color: $maptycs-white;
        background-color: $maptycs-main;
        font-size: $font-normal;
        text-transform: capitalize;
    }

    .dialog-content {
        display: flex;
        flex-grow: 1;
        background-color: $maptycs-white;
        padding: 40px;
        flex-direction: column;

        .dialog-label {
            display: flex;
            width: 135px;
            align-items: center;
            font-size: 0.75rem;

            span{
                font-size: 0.75rem;
            }
        }

        .row {
            display: flex;
            margin-bottom: 8px;
        }

        .buttons-container {
            display: flex;
            justify-content: space-between;
            padding-top: 8px;

            button {
                min-width: 110px;
            }
        }
    }
}

mat-dialog-container.mat-dialog-container {
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.21);
    // width: 26.771rem;
    // height: 17.688rem;
}

.mat-error.name-error {
    position: absolute;
    top: 54px;
    font-size: 10px;
}

.width-mat-input {
    flex: 1;
}