.mat-expansion-panel {
  background: unset !important;
  text-align: right; // for the header
  box-shadow: unset !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #d6d6d6;
}

.mat-expansion-panel-header {
  display: inline-block !important;
  height: 24px !important;
  font-size: 13px !important;
  background: white;
  padding: 0 18px !important;
  border-radius: 0 !important;
  position: relative;
  bottom: -1px;
  border-left: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;

  .mat-content {
    height: 100%;
    //padding-left: 18px;
  }

}

.mat-expansion-panel-body {
  background: white;
  border-top: 1px solid #d6d6d6;
  overflow: auto;
  //border-bottom: 1px solid #d6d6d6;
}

.mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
  background: white !important;
}

.mat-expansion-panel-header-description, .mat-expansion-panel-header-title {
  margin: 0 !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel
.mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: white !important;
}
.panel-body.details-expanded{
	.mat-card-actions{
		margin-left: 0 !important;
		margin-right: 0 !important;
	}	
}

