@import "variables";
@import "colors";

.breadcrumbs {
    position: relative;
    display: flex;
    height: 1rem;
    margin: 0.5rem 0.625rem 0.5rem 0.625rem;
    padding: 0.188rem 0.25rem 0.188rem 0;
    align-items: center;
    z-index: 9;
    background-color: #ededed;
    font-size: 0.75rem;
    // box-shadow: 0px 2px 1px -1px $maptycs-grey-border, 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    >span,
    .breadcrumbs-inner>span {
        margin-right: 4px;
        cursor: default;
        color: $maptycs-main-dark;
        font-size: 0.75rem;

        &.active {
            color: $maptycs-accent;
            font-weight: bold;
        }

        &:after {
            content: "/";
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            margin-left: 4px;
        }

        &:last-of-type {
            color: $maptycs-main-dark;
            font-weight: bold;

            &:after {
                content: none;
            }
        }
    }

    .mat-icon {
        margin-right: 8px;
        height: 15px;
    }

    .page-container {
        padding: 0;
    }

    .breadcrumb-multiple {
        color: #a0a0a0;

        >strong {
            font-weight: normal;
            padding: 0 6px;
            cursor: default;
        }

        >span {
            cursor: pointer;

            &.active {
                color: $maptycs-main-dark;
                font-weight: bold;
            }
        }
    }

    .actions {
        margin-left: auto;
        .mat-flat-button.mat-accent {
            background-color: #ededed !important;
        }

        .mat-flat-button.mat-accent {
            color: #002d57 !important;
        }
        button {
            height: 100%;
            background-color: transparent;
            color: $maptycs-primary;

            &[color='accent'] {
                color: $maptycs-accent;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }
    }
}
