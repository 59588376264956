@import "colors";
@import "variables";

.tree {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0px;
    min-width: 100%;

    .tree-row {

        .tree-node {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 100%;

            &.selected {
                background-color: rgb(255 255 255 / 15%);
                padding-right: 12px;
            }

            .node-navigation {
                display: inline-flex;
                width: 16px;
                height: 16px;
                min-width: 16px;
                margin-right: 2px;

                >mat-icon {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    ul {
        >.tree-row {
            padding-left: 16px;
        }
    }


    mat-checkbox {
        &::ng-deep {
            .mat-checkbox-inner-container {
                height: 0.75rem !important;
                width: 0.75rem !important;
            }

            .mat-checkbox-ripple {
                left: calc(50% - 12px);
                top: calc(50% - 12px);
                height: 24px;
                width: 24px;
            }
        }
    }

    &.tree-menu {
        .tree-row {
            margin: 0;

            .mat-checkbox-frame {
                transform: scale(0.8);
            }

            .mat-checkbox-background {
                transform: scale(0.8);
            }
        }

    }
}