@import 'colors';
@import 'variables';

.mat-menu-header+* {
    .mat-menu-panel {
        background-color: #ffffff;

        hr {
            border-color: transparent;
            border-top: 1px solid #e8e8e8;
        }
    }
    .mat-menu-account-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mat-icon {
            color: #002d57;
            font-size: 16px;

            &:hover {
                color: $maptycs-accent;
            }
        }
    }
}
.mat-menu-panel {
    min-height: unset !important;
    border-radius: 1px !important;
    max-width: max-content !important;
    padding: 0.562rem 0.936rem 1.25rem 1.109rem;
}
.mat-menu-item {
    color: $config-main;
    height: 1.688rem;
    margin: 0px 0 0;
    padding: 0.438rem 0rem 0 0;
    font-size: 0.75rem;
    line-height: 2.8;

    .mat-icon {
        margin: 0 !important;
    }
}
.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
    background-color: $maptycs-accent;
}
html .mat-icon {
    width: unset;
    height: unset;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #3f4756;
}
snack-bar-container.mat-snack-bar-container {
    max-width: unset !important;
    height: 1.875rem;
    padding: 0.3rem 0.33rem 0.812rem 0.33rem;
    border-radius: 1px;
}
::ng-deep .success-snackbar {
    background-color: #00ffa5 !important;
    color: white;
}
::ng-deep .error-snackbar {
    background-color: #00ffa5 !important;
    color: rgb(0, 0, 0);
}
.success-snackbar mat-icon{
    color: black;
}
.error-snackbar mat-icon {
    color: white;
}
mat-tooltip-component .mat-tooltip {
    background-color: $maptycs-main;
    font-size: 0.75rem;
}
.mat-slider-horizontal {
    min-width: 100% !important;
}
map-location-profile {
    .mat-tab-label-container {
        overflow-x: auto !important;
    }

    .mat-tab-header-pagination {
        display: none !important;
    }

    .mat-tab-header {
        width: 93% !important;
    }

    .mat-tab-list {
        transform: translateX(0px) !important;
    }

    .mat-tab-label {
        padding: 0 22px;
    }
}
map-datapoints-profile-panel {
    .actions-button {
        background-color: $maptycs-white;
        right: 0px;
        opacity: unset;
    }
}
.mat-tab-label {
    min-width: unset !important;
    height: 41px !important;
}
.mat-tab-body-wrapper {
    flex-grow: 1;
}
.mat-tab-labels {
    background-color: white;
}
// END TABS

// Cluster Panel
.cluster-error-fields.mat-form-field .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
    margin-top: 1px !important;
    font-size: 10px;
}
// alerts notif checkboxs
.notif-checkbox.mat-checkbox .mat-checkbox-layout {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center
}
.notif-checkbox.mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
    margin: 0 8px 0 0;
}
.notif-checkbox.mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    flex: 2;
}
//disable checkboxes with ripple
.ripple-checkbox .mat-checkbox-ripple.mat-ripple {
    display: none !important;
}
::ng-deep .mat-slide-toggle-bar {
    height: 1.188rem !important;
    width: 2.375rem !important;
    padding: 0.125rem 1.25rem 0.125rem 0.188rem;
    border-radius: 10px;
    background-color: #ededed;

    .mat-slide-toggle-thumb-container {
        position: absolute;
        z-index: 1;
        width: 0.938rem !important;
        height: 0.938rem !important;
        top: 1px !important;
        left: 3px !important;
        transform: translate3d(0, 0, 0);
        transition: all 80ms linear;
        transition-property: transform;

        .mat-slide-toggle-thumb {
            width: 0.938rem !important;
            height: 0.938rem !important;
            border: 1px solid #002d57 !important;
            background-color: #ededed;

        }

    }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $maptycs-accent;
    border: none !important;
}
::ng-deep .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
        background-color: #ededed !important;

        .mat-slide-toggle-thumb-container {
            position: absolute;
            top: 1px !important;
            left: 11px !important;

            .mat-slide-toggle-thumb {
                background-color: #0044f1 !important;
                border: none !important;
            }
        }
    }
}
.snack-bar-container.mat-snack-bar-container {
    max-width: unset !important;
    padding: 0.812rem 0.312rem 0.812rem 0.313rem;
    border-radius: 1px;
}

.success-snackbar {
    background-color: #00ffa5 !important;
    color: black !important;
}
.error-snackbar {
    background-color: #ff0000 !important;
    color: white !important;
}
.success-snackbar .snackbar-icon img {
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}
.error-snackbar .snackbar-icon img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}
.success-snackbar .mat-simple-snack-bar-content,
.error-snackbar .mat-simple-snack-bar-content {
    overflow: hidden;
    text-overflow: ellipsis;
}
.success-snackbar .mat-simple-snackbar-action button,
.error-snackbar .mat-simple-snackbar-action button {
    max-height: 36px;
    min-width: 0;
}
