@import "variables";
@import "colors";

.panel {
    height: 100%;
    width: 300px;
    z-index: 100;
    position: absolute;
    background-color: $maptycs-grey-light;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px;
    font-size: 14px;
    transition: width 0.3s;
    overflow: hidden;

    &.collapsed {
        width: 34px;

        .panel-body {
            display: none;
            height: 100%;

            mat-radio-button {
                display: block;
            }
        }

        .panel-name {
            border: none;
            justify-content: space-around;

            >span {
                display: none;
            }
        }

        &.disabled {
            .panel-name {
                background-color: #ffffff2b;
            }

            mat-icon {
                cursor: auto;
            }
        }
    }

    .section-title {
        color: $maptycs-black;
        margin: 8px 0px;
        font-size: 14px;
        font-weight: bold;
    }
}

.panel-name {
    padding: 0.688rem 0.313rem 0.688rem 0.313rem;
    text-align: center;
    display: flex;
    height: 2.5rem;
    line-height: 1.5;
    font-size: 0.75rem;
    text-transform: capitalize;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e2e2e2;
    white-space: nowrap;
    margin: 0 0 0.625rem;
    color: #002d57;

    >span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    mat-icon {
        cursor: pointer;
    }

    .panel-title {
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize !important;

        .mat-icon {
            color: #002d57;
            width: 0.938rem;
            height: 0.938rem;
            margin-right: 8px;
            cursor: default;

        }
    }

    .panel-actions {
        .material-icons {
            font-size: 1.25rem;
        }

    }

}

.panel-body {
    overflow: auto;
    height: 100%;
    background-color: $maptycs-white;

    .form-container {
        // padding: 0.75rem 0.5rem 1rem 1.063rem;
        margin: 1.25rem 1.125rem 0 1.375rem;
        display: flex;
        height: 100%;
        flex-direction: column;
        background-color: $maptycs-white;

        >form {
            padding: 0.688rem 0.313rem 0.688rem 0.313rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            // overflow-y: auto;
            flex-grow: 1;

            .list {
                background: #ffffff;
                padding: 0.875rem 1.063rem 0.938rem 1.063rem;
                font-size: 0.75rem;
            }
        }

        >button {
            width: 100%;
            margin-top: 8px;
        }

        .create-title {
            margin: 0.75rem 0;
            font-size: 0.75rem;
        }
    }
}

.panel-footer {
    padding: 16px 0px;

    >button {
        width: 100%;
    }
}


.panel-header {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 4px grey;
}