@import "variables";
@import "colors";

.map-list-field {
    > label {
        display: block;
        padding-bottom: 4px;
        font-weight: bold;
        font-size: $font-normal;
    }

    .list {
        display: flex;
        justify-content: space-between;

        mat-checkbox {
            text-transform: capitalize;
        }
    }
}


.mat-form-field-appearance-outline {
    padding-top: 20px;
    font-family: ProximaNova;

    .mat-form-field-wrapper {
        .mat-form-field-flex {
            padding: 0;

            .mat-form-field-outline-start, .mat-form-field-outline-end {
                border-radius: 0;
            }

            .mat-form-field-outline {
                color: $maptycs-grey-border;
            }

            .mat-form-field-outline-thick {
                color: $maptycs-grey-border-darker;

                .mat-form-field-outline-end, .mat-form-field-outline-gap, .mat-form-field-outline-start {
                    border-width: 1px;
                }
            }

            .mat-form-field-outline-gap {
                display: none;
            }

            .mat-form-field-infix {
                height: 40px;
                padding: 0;

                input.mat-input-element {
                    padding: 0 .75em 0 .75em;
                }

                .mat-form-field-label-wrapper {
                    top: -42px;
                    padding-top: 0;
                }
            }

        }

        .mat-select {
            height: 100%;

            .mat-select-trigger {
                height: 100%;
                padding: 0 .75em 0 .75em;

                .mat-select-value {
                    vertical-align: middle;
                }

                .mat-select-arrow-wrapper {
                    transform: none;
                }
            }
        }


        .mat-form-field-label {
            color: $maptycs-font-color;
        }

        .mat-form-field-subscript-wrapper {
            padding: 0;
            margin-top: 2px;
        }
    }

    &.mat-focused {
        .mat-form-field-label-wrapper .mat-form-field-label {
            color: $maptycs-font-color;
        }

        .mat-form-field-flex {
            .mat-form-field-outline-thick {
                color: $maptycs-grey-border-darker;
            }
        }

        .mat-form-field-infix {
            .mat-form-field-label {
                transform: translateY(-1em);
                font-weight: bold;
                font-size: 14px;
            }
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-label-wrapper .mat-form-field-label {
            color: #f44336;
        }
        .mat-input-element{
            caret-color: #f00000 !important;
        }
    }

    &.mat-form-field-can-float {
        &.mat-form-field-should-float {
            .mat-form-field-flex .mat-form-field-label {
                transform: translateY(-1em);
                font-weight: bold;
                font-size: 14px;
            }
        }

        .mat-form-field-flex {
            .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
                transform: translateY(-1em);
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
}

mat-radio-group {
    display: block;
    padding-bottom: 20px;

    mat-label {
        display: block;
        font-weight: bold;
        padding-bottom: 4px;
        font-size: 14px;
    }

    .mat-radio-button {
        font-size: 0.5rem;
        padding-right: 20px;
    }
}

.autocomplete-dropdown {
    // to remove border bottom for autocomplete dropdowns
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        height: 0;
    }

    .mat-form-field-appearance-legacy .mat-form-field-ripple {
        height: 0;
    }
}

.dropdown-small {
    font-size: 12px;
    border-bottom: 1px solid #e0e0e0;
    margin-left: 8px;

    .mat-select-trigger {
        height: 100%;

        .mat-select-value {
            position: relative;
            top: 8px;

            .mat-select-value-text {
                margin-left: 8px;
            }
        }
    }
}

.dropdown-medium {
    font-size: 12px;

    .mat-select-trigger {
        height: 100%;

        .mat-select-value {
            position: relative;
            top: 25px;

            .mat-select-value-text {
                margin-left: 8px;
            }
        }
    }
}

.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding: 0 !important;
}

// colorization input box
.box-input-colorization {
    // opacity: 0;
    display: block;
    width: 25px;
    height: 25px;
    border: none;
    cursor: pointer;
}
.location-interval-input{
    opacity: 1 !important;
    width: 17px;
    height: 17px;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    bottom: 1px;
    right: 1px;
    padding-left: 0rem !important;
}
